import React, { useContext, useEffect, useMemo, useState } from "react";
import { getAllForms, base_url } from "../../api";
import Table from "../../components/Table";
import { AuthContext } from "../../context";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import Loader from "../../components/Loader";
import { Box, Button, Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import axios from "axios";

function InstallEmployeeUserCompany() {
  const { token, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [companyName, setCompanyName] = useState([]);


  const { companyId, userId } = useParams();

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/install/employee/store/${companyId}/${userId}`);
      setData(response.data.data.emplist);
      setCompanyName(response.data.data.companyName)
      
    } catch (error) {}
  };

  const updateTable = (page) => {
    ViewCompanyFn()
    // getAllForms({
    //   token: token,
    //   skip: page * 10,
    //   limit: 10,
    // }).then(({ error, data }) => {
    //   setData(data?.data.length > 0 ? data.data : []);
    //   setCount(data.count);
    // });
  };

  useEffect(() => {
    // if (token) updateTable(0);
    updateTable(0);
  }, [token]);

  const columns = useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "shop_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },  
      {
        Header: "Phone Number",
        accessor: "phone_no",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Visit By",
        accessor: "assiagnedId.name",
      },
    ],
    [token]
  );

  return (
    <div>
    <Grid container spacing={2}>
       <Grid item xs={2}>
       <Sidebar />
       </Grid>
       <Grid item xs={10}>
       <h1 style={{textAlign: "center",
    color: "#283747",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px"}}>
      {companyName}
    </h1>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-around",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          alignItems: "center",
          justifyContent: { xs: "center", sm: "flex-end" },
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {user?.role === "admin" && (
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate("/createUser");
            }}
            sx={{
              alignItems: "left",
              backgroundColor: "#15803d",
              color: "white",

              ":hover": {
                backgroundColor: "#15803d",
                color: "white",
              },
            }}
          >
            Create User
          </Button>
        )}
        <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button>
      </Box> */}
      
      <Table
        columns={columns}
        datas={data}
        updateTable={updateTable}
        count={count}
      />
    </Container>
    </Grid>
    </Grid>
    </div>
  );
}

export default InstallEmployeeUserCompany;
