import { useContext, useState, useEffect } from "react";
import FileInput from "../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../api";
import { AuthContext } from "../context";
import { alertBox } from "../utils/AlertDailog";
import { useNavigate, useParams, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";

function ShopList() {
  const navigate = useNavigate();
  const [seachInput, setSearchInput] = useState("");
  const [dataForSearch, setDataForSearch] = useState([]);
  const { token, user } = useContext(AuthContext);
  const [shop_name, set_shop_name] = useState("");
  const [name, set_name] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [address, set_address] = useState("");
  const [phone_no, set_phone_no] = useState("");
  const [gst, set_gst] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([
    {
      file: null,
      height: "",
      width: "",
      quantity: "",
      requirment_type: "",
    },
  ]);

  const { shopId } = useParams();

  const handleSubmit = async (event) => {
    setisLoading(true);
    event.preventDefault();
    const { error, data } = await saveForm({
      selectedFiles,
      shop_name,
      address,
      phone_no,
      companyId,
      gst,
      name,
      token,
    });
    setisLoading(false);

    if (error?.message) {
      alertBox({ error, data });
    } else {
      alertBox({ error, data }).then((e) => {
        if (user?.role !== "admin") {
          window.location.reload();
        } else navigate("/listForm");
      });
    }
  };

  const [storeList, setStoreList] = useState([]);

  const ViewCompanyFn = async () => {
    try {
      let user = localStorage.getItem("user");
      let userObj = JSON.parse(user);
      let userId = userObj.userId;

      let response = await axios.get(
        `${base_url}/api/company/shopList/${userId}/${shopId}`
      );
      setStoreList(response.data.data.companyList);
      setDataForSearch(response.data.data.companyList);
    } catch (error) {}
  };

  useEffect(() => {
    ViewCompanyFn();
  }, []);

  useEffect(() => {
    setStoreList(
      dataForSearch.filter(
        (_item) =>
          _item.shop_name.toLowerCase().indexOf(seachInput.toLocaleLowerCase()) >= 0 ||
          _item.address.toLowerCase().indexOf(seachInput.toLocaleLowerCase()) >= 0
      )
    );
  }, [seachInput]);

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>

      <Grid item xs={10}>
        <div className="max-w-2xl mx-auto">
          <div className="mb-5">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    value={seachInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    type="search"
                    id="default-search"
                    className="block h-10 w-54 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Work"
                  />
                </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={10}>
        <div className="max-w-2xl mx-auto">
          <div className="p-4 max-w-md bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700"
              >
                {storeList.map((item, index) => {
                  return (
                    <li className="py-3 sm:py-4" value={item._id} key={index}>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          {/* <img
                        className="w-8 h-8 rounded-full"
                        src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                        alt="Neil image"
                      /> */}
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900  dark:text-white">
                            {item.shop_name}
                          </p>
                          <p className="text-sm font-medium text-gray-900  dark:text-white mt-3">
                            {/* Address: */}
                            <span>
                              {item.address} {item.area}
                            </span>
                          </p>
                        </div>                         
                      </div>
                      <div className="flex items-center space-x-4 mt-4">
                      <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white text-center">
                        <a
                          href={"tel:" + item.phone_no}
                          className="items-center btnCommon text-base font-semibold text-white-900 dark:text-white w-24 h-10 rounded-xl bg-slate-500 text-center"
                          style={{
                            background: "rgb(21, 128, 61)",
                            color: "white",
                            padding: "5px",
                          }}
                        >
                          Call Owner
                        </a>
                      </p>
                      </div>

                      <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white text-center">
                        {item.status == "completed" ? (
                          <Link
                            className="items-center text-base font-semibold text-white-900 dark:text-white w-100 h-10 rounded-xl bg-slate-500 text-center"
                            style={{
                              background: "rgb(21, 128, 61)",
                              color: "white",
                            }}
                          >
                            Completed
                          </Link>
                        ) : (
                          <Link
                            className="btnCommon items-center text-base font-semibold text-white-900 dark:text-white w-100 h-10 rounded-xl bg-slate-500 text-center"
                            style={{
                              background: "rgb(21, 128, 61)",
                              color: "white",
                              padding: "5px",
                              width: "20%",
                            }}
                            to={`/createForm/${item._id}`}
                          >
                            Submit
                          </Link>
                        )}
                         </p>
                      </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ShopList;
