import { useContext, useState, useEffect } from "react";
import FileInput from "../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url, addList } from "../api";
import { AuthContext } from "../context";
import { alertBox } from "../utils/AlertDailog";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";
import Loader from "../components/Loader";

function CreateAdminForm() {
  const navigate = useNavigate();
  const { token, user } = useContext(AuthContext);
  const [shop_name, set_shop_name] = useState("");
  const [name, set_name] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [address, set_address] = useState("");
  const [phone_no, set_phone_no] = useState("");
  const [area, setArea] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [visitedBy, setVisitedBy] = useState("");
  const data = {
    name: name,
    shop_name: shop_name,
    address: address,
    phone_no: phone_no,
    area: area,
    companyId: companyId,
    visitedBy: visitedBy,
  };

  const handleSubmit = async (event) => {
    setisLoading(true);
    event.preventDefault();

    const { error, data } = await addList({
      shop_name,
      address,
      phone_no,
      companyId,
      area,
      visitedBy,
    });
    setisLoading(false);

    if (error?.message) {
      alertBox({ error, data });
    } else {
      alertBox({ error, data }).then((e) => {
        if (user?.role !== "admin") {
          window.location.reload();
        } else navigate(`/companyView/${companyId}`);
      });
    }
  };

  const handleInputChange = (ev) => {
    const inputValue = ev.target.value;
  
    // Remove any non-digit characters from the input
    const numericInput = inputValue.replace(/\D/g, '');
  
    // Limit the input to exactly 10 digits
    if (numericInput.length <= 10) {
      set_phone_no(numericInput);
    }
  };

  const [company, setCompany] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const ViewCompanyFn = async () => {
    try {
      // let response = await axios.get(`${base_url}/api/company`, common.header);
      let response = await axios.get(`${base_url}/api/company`);
      setCompany(response.data.data);
    } catch (error) {}
  };

  const ViewEmployeeListFn = async () => {
    try {
      // let response = await axios.get(`${base_url}/api/company`, common.header);
      let response = await axios.get(`${base_url}/api/company/user`);
      setEmployeeList(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    ViewCompanyFn();
    ViewEmployeeListFn();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <form
            onSubmit={handleSubmit}
            className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 "
          >
            <label className="font-bold" htmlFor="shop_name">
              Store Name
            </label>
            <input
              type="text"
              id="shop_name"
              onChange={(ev) => set_shop_name(ev.target.value)}
              value={shop_name}
              required
            />

            <label className="font-bold" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              onChange={(ev) => set_address(ev.target.value)}
              value={address}
              required
            />

            {/* new add */}
            <label className="font-bold" htmlFor="address">
              Area
            </label>
            <input
              type="text"
              id="area"
              onChange={(ev) => setArea(ev.target.value)}
              value={area}
              required
            />

            <label className="font-bold" htmlFor="phone_no">
            Phone Number
            </label>
            <input
               type="tel"
               pattern="[0-9]{10}"
               id="phone_no"
               onChange={(ev) => handleInputChange(ev)}
               value={phone_no}
               required
            />

            <label className="font-bold" htmlFor="phone_no">
              Company Name
            </label>
            <select
              onChange={(ev) => setCompanyId(ev.target.value)}
              value={companyId}
              required
              placeholder="Name"
            >
              <option selected>Select Company</option>
              {company.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>

            {/* <label className="font-bold" htmlFor="gst">
          GST
        </label> */}

            {/* <input
          type="text"
          id="gst"
          onChange={(ev) => set_gst(ev.target.value)}
          value={gst}
          required
        /> */}

            <label className="font-bold" htmlFor="name">
              Visit By
            </label>

            <select
              onChange={(ev) => setVisitedBy(ev.target.value)}
              value={visitedBy}
              required
              placeholder="Name"
            >
              <option selected>Select Visit By</option>
              {employeeList.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* <input
          type="text"
          id="name"
          onChange={(ev) => set_name(ev.target.value)}
          value={name}
          required
        /> */}

            {/* <FileInput
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          isLoading={isLoading}
        /> */}
            <button
              type="submit"
              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                isLoading ? "bg-slate-400" : ""
              } `}
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : "Submit"}
            </button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default CreateAdminForm;
