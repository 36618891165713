import React, { useContext, useEffect, useMemo, useState } from "react";
import { getAllForms, base_url } from "../../api";
import Table from "../../components/Table";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { AuthContext } from "../../context";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
// import Loader from "../../components/Loader";
import * as XLSX from "xlsx/xlsx.mjs";
import { Alert, Box, Button, Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { alertBox } from "../../utils/AlertDailog";
import { PermissionForDelete } from "../../utils/PermissionDialog";

function AllWorks() {
  const { token, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(0);
  const [dataForFilter, setDataForFilter] = useState([]);
  const [count, setCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [companyName, setCompanyName] = useState([]);

  // const { companyId } = useParams();
  const companyId = "6475cc295e4ed78592d4d660";

  const deleteEntity = async (storeId) => {
    try {
      let currStoreForDelete = data.find((_item) => storeId === _item._id);
      if (currStoreForDelete.status === "completed") {
        PermissionForDelete().then(async (res) => {
          if (res) {
            await axios.delete(`${base_url}/api/company/addList/${storeId}`);
            setData((prev) => prev.filter((_item) => storeId !== _item._id));
            alertBox({ data: { message: "Store Deleted Successfully" } });
          }
        });
        return;
      } else {
        alertBox({ data: { message: "You Can't Deleted This Store" } });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/company/store/${companyId}`
      );

      setPending(
        response.data.data.companyList.reduce((prev, item) => {
          if (item.status === "pending") return (prev += 1);
          return prev;
        }, 0)
      );

      setDataForFilter(response.data.data.companyList);

      setData(
        response.data.data.companyList.sort((a, b) => {
          if (a.status < b.status) return 1;
          if (a.status > b.status) return -1;
          return 0;
        })
      );
      setCompanyName(response.data.data.companyName);
    } catch (error) {}
  };

  const updateTable = async (page) => {
    ViewCompanyFn();
    try {
      const { data } = await axios.get(`${base_url}/api/install/work`);
      console.log("allWork", data.data);
      setData(data.data);
    } catch (error) {}
    // getAllForms({
    //   token: token,
    //   skip: page * 10,
    //   limit: 10,
    // }).then(({ error, data }) => {
    //   setData(data?.data.length > 0 ? data.data : []);
    //   setCount(data.count);
    // });
  };

  useEffect(() => {
    // if (token) updateTable(0);
    updateTable();
  }, [token]);
  const exportToXcel = async (id) => {
    try {
      const { data } = await axios.get(
        `${base_url}/api/company/download-xcel/${id}`
      );

      if (data.length !== 0 && data?.data[0]) {
        const currData = data.data[0];
        const columnHeader = [
          "name",
          "shop_name",
          "address",
          "gst",
          "phone_no",
          "status",
          "location",
          "height",
          "width",
          "quantity",
          "requirementType",
        ];
        const { formDetails: deatails } = currData;

        delete currData.formDetails;
        let newData = [
          [
            ...Object.keys(currData).map((key) => currData[key]),
            ...Object.values(deatails[0]),
          ],
          ...deatails.map((item) => [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ...Object.values(item),
          ]),
        ];

        newData.unshift(columnHeader);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(newData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
      } else {
        alertBox({ data: { message: "There is no Data Available" } });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      Header: "Store Name",
      accessor: "shop_name",
    },
    {
      Header: "Area",
      accessor: "address",
    },
    {
      Header: "Assiagned to",
      accessor: "assiagnedId.name",
    },
    {
      Header: "Download",
      accessor: "formId._id",
      callback: (id) => {
        return (
          <button type="submit" onClick={() => exportToXcel(id)}>
            <ImportExportIcon />
          </button>
        );
      },
    },
    {
      Header: "Delete ",
      accessor: "_id",
      callback: (id, status) => {
        return (
          <button
            type="submit"
            onClick={() => {
              deleteEntity(id);
            }}
          >
            <DeleteIcon />
          </button>
        );
      },
    },

    {
      Header: "Stauts",
      accessor: "status",
    },
  ];

  useEffect(() => {
    setData(
      dataForFilter.filter(
        (_item) =>
          _item.shop_name
            .toLowerCase()
            .indexOf(searchInput.toLocaleLowerCase()) >= 0
      )
    );
  }, [searchInput]);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10}>
          <h1
            style={{
              textAlign: "center",
              color: "#283747",
              fontSize: "28px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {companyName}
          </h1>
          <p
            className="btnCommon"
            style={{
              textAlign: "center",
              color: "red",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            EXPORT ALL
          </p>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-around",
            }}
          >
            <div className="mr-5">
              <span
                style={{
                  textAlign: "center",
                  color: "#283747",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Pending : {pending}{" "}
              </span>
              <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div class="relative">
                <div class="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  id="default-search"
                  class="block h-10 w-54 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Store"
                />
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                marginBottom: "10px",
                width: "100%",
              }}
            >
              {/* {user?.role === "admin" && (
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    // navigate("/createUser");
                  }}
                  sx={{
                    alignItems: "left",
                    backgroundColor: "#15803d",
                    color: "white",

                    ":hover": {
                      backgroundColor: "#15803d",
                      color: "white",
                    },
                  }}
                >
                  Create User
                </Button>
              )} */}
              {/* <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button> */}
            </Box>

            {data && (
              <Table
                columns={columns}
                datas={data}
                updateTable={updateTable}
                count={count}
              />
            )}
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default AllWorks;
