import * as yup from "yup";
export const bulkAddStoreDetailsValidation = yup.object().shape({
  companyId: yup.string().required("Company Name is Required"),
  visitedBy: yup.string().required("VisitedBy Name is Required"),
  store_details: yup
    .array()
    .of(
      yup.object().shape({
        shop_name: yup.string().required("Shop name is Required"),
        address: yup.string().required("Address is Required"),
        phone_no: yup.string().required("Contact Number is Required"),
        area: yup.string().required("Area is Required"),
      })
    )
    .required("Please fill all the Fields"),
});
export const bulkAddStoreValidation = yup.object().shape({
  companyId: yup.string().required("Company Name is Required"),
  visitedBy: yup.string().required("VisitedBy Name is Required"),
});
