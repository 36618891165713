import React, { useContext, useEffect, useMemo, useState } from "react";
import { getAllForms, base_url } from "../api";
import Table from "../components/Table";
import { AuthContext } from "../context";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx/xlsx.mjs";
// import Loader from "../components/Loader";
import { Box, Button, Container, Grid } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { alertBox } from "../utils/AlertDailog";
import { PermissionForDelete } from "../utils/PermissionDialog";

function MyEmployee() {
  const [seachInput, setSearchInput] = useState("");
  const { token, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataForSearch, setDataForSearch] = useState([]);
  const [count, setCount] = useState(0);

  const userFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/company/user`);
      setData(response.data.data);
      setDataForSearch(response.data.data);
    } catch (error) {}
  };

  const updateTable = (page) => {
    userFn();
    // getAllForms({
    //   token: token,
    //   skip: page * 10,
    //   limit: 10,
    // }).then(({ error, data }) => {
    //   setData(data?.data.length > 0 ? data.data : []);
    //   setCount(data.count);
    // });
  };

  const deleteEmploye = async (companyId) => {
    try {
      PermissionForDelete().then(async (res) => {
        if (res) {
          await axios.delete(`${base_url}/api/company/user/${companyId}`);
          setData((prev) => prev.filter((_item) => companyId !== _item._id));
          alertBox({ data: { message: "Employee Deleted Successfully" } });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (token) updateTable(0);
    updateTable(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const columns = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "name",
      },
      {
        Header: "View Employee",
        accessor: "_id",
        callback: (id) => {
          return <Link to={`/employeeView/${id}`}>View Employee</Link>;
        },
      },
      {
        Header: "Delete Employee",
        accessor: "_id",
        callback: (id) => {
          return (
            <button type="submit" onClick={() => deleteEmploye(id)}>
              <DeleteIcon />
            </button>
          );
        },
      },
      // {
      //   Header: "Created At",
      //   accessor: "createdAt",
      //   callback: (value) => {
      //     return moment(value).format("DD-MM-YYYY");
      //   },
      // },
      // {
      //   Header: "Download",
      //   accessor: "_id",
      //   callback: (id) => {
      //     return (
      //       <form method="post" action={`/api/user/download-ppt/${id}`}>
      //         <input type="hidden" value={token} name="authorization" />
      //         <button type="submit">
      //           <CloudDownloadIcon />
      //         </button>
      //       </form>
      //     );
      //   },
      //   // onClick: (id) => {
      //   //   getFormPpt({ id, token });
      //   // },
      // },
    ],
    [token]
  );

  // if (isLoading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );

  const exportToXcel = async () => {
    const newData = data.map((item) => Object.values(item));
    // Add headers to the first row of the data array
    newData.unshift(Object.keys(data[0]));
    // Create a new workbook and worksheet
    console.log(newData)
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(newData);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as a file
    XLSX.writeFile(workbook, "data.xlsx");
  };
  useEffect(() => {
    setData(
      dataForSearch.filter(
        (_item) =>
          _item.name.toLowerCase().indexOf(seachInput.toLocaleLowerCase()) >= 0
      )
    );
  }, [seachInput]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              justifyContent: { xs: "center", sm: "flex-end" },
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <div className="mr-5">
              <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div class="relative">
                <div class="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  value={seachInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  id="default-search"
                  class="block h-10 w-54 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Company"
                />
              </div>
            </div>
            <Button
             className="btnCommon"
              style={{ marginRight: "10px" }}
              onClick={() => {
                exportToXcel();
              }}
              sx={{
                alignItems: "left",
                backgroundColor: "#15803d",
                color: "white",

                ":hover": {
                  backgroundColor: "#15803d",
                  color: "white",
                },
              }}
            >
              Export
            </Button>
            {user?.role === "admin" && (
              <Button
              className="btnCommon"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate("/createUser ");
                }}
                sx={{
                  alignItems: "left",
                  backgroundColor: "#15803d",
                  color: "white",

                  ":hover": {
                    backgroundColor: "#15803d",
                    color: "white",
                  },
                }}
              >
                Add Employee
              </Button>
            )}
            {/* <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button> */}
          </Box>
          <Table
            columns={columns}
            datas={data}
            updateTable={updateTable}
            count={count}
          />
        </Container>
      </Grid>
    </Grid>
  );
}

export default MyEmployee;
