import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url, getAllForms } from "../api";
import axios from "axios";
import { Box, Button, Modal, TextField } from "@mui/material";
import { AuthContext } from "../context";
import { alertBox } from "../utils/AlertDailog";
const style = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const AddModal = ({ open, setOpen }) => {
  const [image, setImage] = useState("");
  const [company, setCompanyName] = useState("");
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [count, setCount] = useState(0);
  const [Company, setCompany] = useState({
    name: "",
    logoUrl: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setImage("");
    setOpen(false);
  };
  const addCompanyFn = async () => {
    try {
      let response = await axios.post(`${base_url}/api/company`, Company);
    } catch (error) {}
  };

  const createCompany = async () => {
    try {
      let payload = {
        name: company,
        logoUrl: imageUrl,
        total: 0,
        completed: 0,
        pending: 0,
        cancelled: 0,
        revisit: 0,
      };
      const res = await axios.post(`${base_url}/company`, payload);
    
      setData((prev) => [...prev, payload]);
      alertBox("", "Successfully Comapany Added", "Ok");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const choseImage = async (e) => {
    const img = new FormData();
    img.append("file", e.target.files[0]);
    img.append("upload_preset", "etzuxpbj");
    img.append("cloud_name", "dpfjqvdlv");
    const { data } = await axios.post(
      `https://api.cloudinary.com/v1_1/dpfjqvdlv/image/upload`,
      img
    );
    
    setImageUrl(data.url);
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-6 text-4xl font-bold">Add Company</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <div
              className=""
              component="form"
              sx={{
                m: 1,
                width: 300,
              }}
              noValidate
              autoComplete="off"
            >
              <p className="mb-2">Enter Company Name</p>
              <TextField
                className="w-100"
                id="outlined-basic"
                placeholder="Enter Company Name"
                variant="outlined"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <span style={{ height: "21px" }}></span>
            </div>
            {image && (
              <div class="flex flex-wrap justify-center mt-10">
                <img
                  src={image}
                  class="h-auto max-w-sm rounded-lg shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
                  alt=""
                />
              </div>
            )}
            <div class="flex justify-center">
              <div class="mb-3  mt-4 w-56">
                <label for="formFileLg" class="mb-2 inline-block">
                  Choose Image
                </label>
                <input
                  onChange={(event) => choseImage(event)}
                  class="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding py-[0.32rem] px-3 font-normal leading-[2.15] text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100"
                  id="formFileLg"
                  type="file"
                />
              </div>
            </div>
          </div>

          {/* second col */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              width: "420px",
            }}
          >
            <Button
              style={{ width: "200px", height: "50px" }} //
              onClick={() => {
                createCompany();
              }}
              sx={{
                alignItems: "left",
                backgroundColor: "#15803d",
                color: "white",

                ":hover": {
                  backgroundColor: "#15803d",
                  color: "white",
                },
              }}
            >
              Save Company
            </Button>
            <Button
              style={{ width: "200px", height: "50px" }} //
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                alignItems: "left",
                backgroundColor: "#15803d",
                color: "white",

                ":hover": {
                  backgroundColor: "#15803d",
                  color: "white",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export { AddModal };
