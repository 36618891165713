import swal from "sweetalert";

export const PermissionForDelete = () => {
  return swal({
    title: "Are you sure?",
    icon: "warning",
    dangerMode: true,
    text: "You want to delete ",
    buttons: true,
  });
};
