import { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { base_url, addList } from "../api";
import { AuthContext } from "../context";
import { alertBox } from "../utils/AlertDailog";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Grid } from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import Table from "../components/Table";
import { useFormik } from "formik";
import { bulkAddStoreValidation } from "../feature/validation";
import Loader from "../components/Loader";
function CreateBulkForm() {
  const navigate = useNavigate();
  const { token, user } = useContext(AuthContext);
  const [isLoading, setisLoading] = useState(false);

  const [data, setData] = useState([]);

  const uploadAllData = async (values) => {
    try {
      const { companyId, visitedBy } = values;
      const payloadArr = data.map((item) => {
        return { ...item, companyId: companyId, visitedBy: visitedBy };
      });
      const res = await Promise.all(
        payloadArr.map((payload) => addList(payload))
      );
      if (res) {
        alertBox({
          data: { message: "Data uploaded SuccessFully" },
        });
        setData([]);
      }
    } catch (error) {
      alertBox({ error, data });
    }
  };

  const formik = useFormik({
    validationSchema: bulkAddStoreValidation,
    initialValues: {
      companyId: "",
      visitedBy: "",
    },
    onSubmit: (values) => {
      if (data.length === 0) {
        alertBox({
          data: { message: "File is Empty Please choose Another file" },
        });
        return;
      }
      uploadAllData(values);
    },
  });

  const [company, setCompany] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/company`);
      setCompany(response.data.data);
    } catch (error) {}
  };

  const ViewEmployeeListFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/company/user`);
      setEmployeeList(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    ViewCompanyFn();
    ViewEmployeeListFn();
  }, []);

  const columns = [
    {
      Header: "Store Name",
      accessor: "shop_name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Contact Number",
      accessor: "phone_no",
    },
    {
      Header: "Area",
      accessor: "area",
    },
  ];
  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      console.log();
      if (!file)
        alertBox({
          data: { message: "Something went wrong or unable to read the file" },
        });
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 2 });
        setData(jsonData);
      };
    } catch (error) {
      console.log(error);
      alertBox({
        data: { message: "Something went wrong or unable to read the file" },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <form className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 ">
            <label className="font-bold" htmlFor="phone_no">
              Company Name
            </label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              name="companyId"
              placeholder="Name"
            >
              <option selected>Select Company</option>
              {company.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.companyId && (
              <p className="text-red-600 text-lg">{formik.errors.companyId}</p>
            )}
            <label className="font-bold" htmlFor="name">
              Visit By
            </label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="visitedBy"
              required
              placeholder="Name"
            >
              <option selected>Select Visit By</option>
              {employeeList.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.visitedBy && (
              <p className="text-red-600 text-lg">{formik.errors.visitedBy}</p>
            )}
            {data.length === 0 ? (
              <input
                className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300  text-cyan-400 bg-clip-padding px-3 py-[0.32rem] font-normal leading-[2.15] text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-cyan-400 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
              />
            ) : (
              <>
                <label
                  className="font-bold text-4xl text-cyan-400 text-center mt-8"
                  htmlFor="address"
                >
                  Store Details
                </label>
                <Table
                  columns={columns}
                  datas={data}
                  updateTable={() => {}}
                  count={0}
                />
                <div className="flex flex-1">
                  <button
                    onClick={formik.handleSubmit}
                    type="button"
                    disabled={formik.isSubmitting ? true : false}
                    className={`flex items-center justify-center mt-4 btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center flex-1 md-96${
                      isLoading ? "bg-slate-400" : ""
                    } `}
                  >
                    {formik.isSubmitting ? <Loader /> : "Submit"}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default CreateBulkForm;
