import { useContext, useState, useEffect } from "react";
import FileInput from "../../../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../../../api";
import { AuthContext } from "../../../context";
import { alertBox } from "../../../utils/AlertDailog";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";

function InstallCompanyEmployeeList() {
  const navigate = useNavigate();
  const { token, user } = useContext(AuthContext);
  const [shop_name, set_shop_name] = useState("");
  const [name, set_name] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [address, set_address] = useState("");
  const [phone_no, set_phone_no] = useState("");
  const [gst, set_gst] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([
    {
      file: null,
      height: "",
      width: "",
      quantity: "",
      requirment_type: "",
    },
  ]);

  const handleSubmit = async (event) => {
    setisLoading(true);
    event.preventDefault();
    const { error, data } = await saveForm({
      selectedFiles,
      shop_name,
      address,
      phone_no,
      companyId,
      gst,
      name,
      token,
    });
    setisLoading(false);

    if (error?.message) {
      alertBox({ error, data });
    } else {
      alertBox({ error, data }).then((e) => {
        if (user?.role !== "admin") {
          window.location.reload();
        } else navigate("/listForm");
      });
    }
  };

  const [company, setCompany] = useState([]);

  const ViewCompanyFn = async () => {
    try {
      let user = localStorage.getItem("user");
      let userObj = JSON.parse(user);
      let userId = userObj.userId;
      let response = await axios.get(
        `${base_url}/api/install/employee/company/addWork/${userId}`
      );
      setCompany(response.data.data.companyList);
    } catch (error) {}
  };

  useEffect(() => {
    ViewCompanyFn();
  }, []);

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={10}>
        {company.length !== 0 ? (
          <div className="max-w-2xl mx-auto">
            <div className="p-4 max-w-md bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="flow-root">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 dark:divide-gray-700"
                >
                  {company.map((item, index) => {
                    return (
                      <li className="py-3" value={item._id} key={index}>
                        <div className="flex items-center space-x-4">
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                              {item._id.name}
                            </p>
                          </div>
                          <button
                            onClick={() =>
                              navigate(`/installEmployee/${item._id.companyId}`)
                            }
                            className="items-center text-base font-semibold text-white-900 dark:text-white w-24 h-10 rounded-xl bg-slate-500"
                          >
                            {item.count}
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto">
            <div className="p-4 max-w-md bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="flow-root justify-center items-center">
                <h1 className="text-white text-center">No Work Assigned</h1>
              </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default InstallCompanyEmployeeList;
