import React, { useRef, useEffect } from "react";
import { fabric } from "fabric";
import { Check, CropSquare, StackedLineChart, Undo } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { Gesture } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const MarkingTool = ({
  selectedFiles,
  currentIndex,
  setSelectedFiles,
  handleClose,
}) => {
  const canvasRef = useRef(null);

  let canvas = null;

  let drawingMode = null;

  const handleDrawingMode = (mode) => {
    if (drawingMode === mode) return;

    if (
      drawingMode === "rectangle" ||
      drawingMode === "line" ||
      drawingMode === "freehand"
    ) {
      canvas.off("mouse:down", startDrawingRectangle);
      canvas.off("mouse:down", startDrawingLine);
      canvas.off("mouse:down", startFreehandDrawing);
    }
    drawingMode = mode;

    if (mode === "rectangle") {
      canvas.isDrawingMode = false;
      canvas.selection = false;
      canvas.forEachObject((obj) => {
        obj.selectable = false;
      });
      canvas.on("mouse:down", startDrawingRectangle);
    } else if (mode === "line") {
      canvas.isDrawingMode = false;
      canvas.selection = false;
      canvas.forEachObject((obj) => {
        obj.selectable = false;
      });
      canvas.on("mouse:down", startDrawingLine);
    } else if (mode === "freehand") {
      canvas.isDrawingMode = true;
      canvas.selection = false;
      canvas.forEachObject((obj) => {
        obj.selectable = false;
      });
      canvas.on("mouse:down", startFreehandDrawing);
    }
  };
  const startFreehandDrawing = () => {
    canvas.freeDrawingBrush.width = 5;
    canvas.freeDrawingBrush.color = "red";
    canvas.on("mouse:up", stopFreehandDrawing);
  };

  const stopFreehandDrawing = () => {
    canvas.off("mouse:up", stopFreehandDrawing);
  };

  const startDrawingRectangle = (event) => {
    const pointer = canvas.getPointer(
      event.e.touches ? event.e.touches[0] : event.e
    );

    const { clientX, clientY } = event.e.touches ? event.e.touches[0] : event.e;

    const rect = new fabric.Rect({
      left: pointer.x,
      top: pointer.y,
      fill: "transparent",
      stroke: "red",
      strokeWidth: 10,
      width: 0,
      height: 0,
    });
    canvas.add(rect);

    const updateRectangle = (option) => {
      const e = option.e.touches ? option.e.touches[0] : option.e;
      rect.set("width", e.clientX - clientX);
      rect.set("height", e.clientY - clientY);
      rect.setCoords();
      canvas.renderAll();
    };

    const stopDrawingRectangle = () => {
      canvas.off("mouse:move", updateRectangle);
      canvas.off("mouse:up", stopDrawingRectangle);
      canvas.off("touch:move", updateRectangle);
      canvas.off("touch:end", stopDrawingRectangle);
    };

    canvas.on("mouse:move", updateRectangle);
    canvas.on("mouse:up", stopDrawingRectangle);
    canvas.on("touch:move", updateRectangle);
    canvas.on("touch:end", stopDrawingRectangle);
  };

  const startDrawingLine = (event) => {
    const pointer = canvas.getPointer(
      event.e.touches ? event.e.touches[0] : event.e
    );

    const line = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      stroke: "red",
      strokeWidth: 10,
    });
    canvas.add(line);

    const updateLine = (event) => {
      if (line) {
        const updatedPointer = canvas.getPointer(
          event.e.touches ? event.e.touches[0] : event.e
        );

        line.set({ x2: updatedPointer.x, y2: updatedPointer.y });
        canvas.renderAll();
      }
    };

    const stopDrawingLine = () => {
      canvas.off("mouse:move", updateLine);
      canvas.off("mouse:up", stopDrawingLine);
      canvas.off("touch:move", updateLine);
      canvas.off("touch:end", stopDrawingLine);
    };

    canvas.on("mouse:move", updateLine);
    canvas.on("mouse:up", stopDrawingLine);
    canvas.on("touch:move", updateLine);
    canvas.on("touch:end", stopDrawingLine);
  };

  const handleUndo = () => {
    const objects = canvas.getObjects();
    if (objects.length > 0) {
      const lastObject = objects[objects.length - 1];
      canvas.remove(lastObject);
      canvas.renderAll();
    }
  };

  useEffect(() => {
    canvas = new fabric.Canvas(canvasRef.current);
    canvas.freeDrawingBrush.width = 5;
    canvas.freeDrawingBrush.color = "red";
    handleImageFromState();

    return () => {
      canvas.dispose();
    };
  }, []);

  const handleSaveCanvas = () => {
    const dataURL = canvas.toDataURL("image/png");

    setSelectedFiles((prevSelectedFiles) => {
      return prevSelectedFiles.map((item, i) => {
        if (i === currentIndex) {
          return {
            ...item,
            file: dataURL,
          };
        }
        return item;
      });
    });

    handleClose();
  };

  const handleImageFromState = () => {
    const file = selectedFiles[currentIndex].tempFile;
    const reader = new FileReader();

    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        const image = new fabric.Image(imgObj);
        const canvasWrapper = canvas.getElement().parentNode;

        // Calculate the aspect ratio of the image
        const aspectRatio = image.width / image.height;

        // Calculate the maximum width and height based on the available space
        const maxWidth = canvasWrapper.clientWidth * 1.7;
        const maxHeight = canvasWrapper.clientHeight * 1.7;

        // Calculate the scaled dimensions while maintaining the aspect ratio
        let scaledWidth = maxWidth;
        let scaledHeight = maxWidth / aspectRatio;

        if (scaledHeight > maxHeight) {
          scaledHeight = maxHeight;
          scaledWidth = maxHeight * aspectRatio;
        }

        // Set the canvas size and scale the image accordingly
        canvas.setDimensions({
          width: scaledWidth,
          height: scaledHeight,
        });

        image.set({
          left: 0,
          top: 0,
          scaleX: scaledWidth / image.width,
          scaleY: scaledHeight / image.height,
          selectable: false,
        });

        canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas));
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="flex justify-center mb-3 mt-3 flex-col items-center">
        <canvas ref={canvasRef} id="canvas" height="428" width="321" />
        <div
          style={{
            bottom: "30px",
          }}
          className="mt-3 absolute sm:static"
        >
          <Button
            onClick={() => handleDrawingMode("rectangle")}
            className=" p-2"
            style={{
              border: "1px solid black",
            }}
          >
            <CropSquare />
          </Button>

          <Button
            onClick={() => handleDrawingMode("line")}
            className=" p-2"
            style={{
              border: "1px solid black",
            }}
          >
            <StackedLineChart />
          </Button>
          <Button
            onClick={() => handleDrawingMode("freehand")}
            className=" p-2"
            style={{
              border: "1px solid black",
            }}
          >
            <Gesture />
          </Button>
          <Button
            onClick={handleUndo}
            className=" p-2"
            style={{
              border: "1px solid black",
            }}
          >
            <Undo />
          </Button>
        </div>
      </div>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: 16,
          right: 20,
        }}
        onClick={handleSaveCanvas}
      >
        <Check />
      </Button>
    </>
  );
};

export default MarkingTool;
