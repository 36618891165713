import React, { useState } from "react";
import FileModal from "./FileModal";
import Loader from "../Loader";
import ImageMarkingModal from "../Modal/ImageMarkingModal";

const FileInput = ({ selectedFiles, setSelectedFiles, isLoading }) => {
  console.log(selectedFiles);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = async (event, index) => {
    setCurrentIndex(index);
    const newSelectedFiles = [...selectedFiles];

    newSelectedFiles[index] = {
      ...selectedFiles[index],
      // file: event.target.files[0],
      tempFile: event.target.files[0],
    };
    setSelectedFiles(newSelectedFiles);
    setOpen(true);
  };

  const handleDelete = (event, index) => {
    setSelectedFiles(selectedFiles.filter((e, i) => i !== index));
  };

  const handleAddFileInput = () => {
    setSelectedFiles([
      ...selectedFiles,
      {
        file: null,
        height: "",
        width: "",
        quantity: 1,
        remarks: "",
        requirment_type: "",
      },
    ]);
  };

  return (
    <>
      <div className="flex flex-col space-y-5">
        <FileModal
          index={0}
          handleFileChange={handleFileChange}
          selectedFiles={selectedFiles[0]}
          setSelectedFiles={setSelectedFiles}
        />
        
        {selectedFiles.map((file, index) =>
          index !== 0 ? (
            <FileModal
              key={index}
              index={index}
              handleDelete={handleDelete}
              handleFileChange={handleFileChange}
              selectedFiles={selectedFiles[index]}
              setSelectedFiles={setSelectedFiles}
            />
            
          ) : null
        )}
        <div
          className="flex flex-r
      space-x-5"
        >
          <button type="button" className="btn btnCommon" onClick={handleAddFileInput}>
            Add more
          </button>

          <button
            type="submit"
            className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
              isLoading ? "bg-slate-400" : ""
            } `}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : "Submit"}
          </button>
        </div>
      </div>
      {currentIndex >= 0 && (
        <ImageMarkingModal
          open={open}
          handleClose={handleClose}
          currentIndex={currentIndex}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        
      )}
    </>
  );
};

export default FileInput;
