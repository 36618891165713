import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { ArrowBack, Check } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import MarkingTool from "../MarkingTool";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ImageMarkingModal = ({
  open,
  handleClose,
  selectedFiles,
  setSelectedFiles,
  currentIndex,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
      fullScreen={fullScreen}
    >
      <DialogContent
        dividers
        style={{
          flexGrow: 0,
        }}
      >
        <div className="flex justify-between">
          <div>
            <Button onClick={handleClose} color="primary">
              <ArrowBack />
            </Button>
            <span className="ml-2"> Image Editor</span>
          </div>
        </div>
      </DialogContent>
      <div className="">
        <MarkingTool
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentIndex={currentIndex}
          handleClose={handleClose}
        />
      </div>
    </BootstrapDialog>
  );
};
export default ImageMarkingModal;
