import { useContext, useState, useEffect, useRef } from "react";
import FileInput from "../../../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../../../api";
import { AuthContext } from "../../../context";
import { alertBox } from "../../../utils/AlertDailog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";
import Loader from "../../../components/Loader";
import {
  Permission,
  PermissionForDelete,
} from "../../../utils/PermissionDialog";

const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function InstallCreateForm() {
  const { allWorkId } = useParams();
  console.log(allWorkId);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const [workDetail, setWorkDetail] = useState({});
  const [additionWorkDetail, setAdditionWorkDetail] = useState([]);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");

  const [previewImages, setPreviewImages] = useState([]);
  const [locationData, setLocationData] = useState(null);

  const canvasRef = useRef(null);

  const allWorkDetailsFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/install/work/image/${allWorkId}`
      );
      setWorkDetail(response.data.data);

      setAdditionWorkDetail(response.data.data);
      setShopName(response.data.data[0].allWorkId.shop_name);
      setShopAddress(response.data.data[0].allWorkId.address);
    } catch (error) {}
  };

  useEffect(() => {
    allWorkDetailsFn();
  }, []);
  const imageHandler = (event, index) => {
    let task = [...additionWorkDetail];
    const formData = new FormData();
    formData.append("uploadedFile", event.target.files[0]);
    task[index].imageUrl = URL.createObjectURL(event.target.files[0]);
    task[index].imgFile = formData;

    // for canvas image preview upload

    if (previewImages[index] === "deleted") {
      const newPreviewImages = [...previewImages];
      newPreviewImages[index] = "uploadingNew";

      // Update the previewImages state
      setPreviewImages(newPreviewImages);
    }

    setAdditionWorkDetail(task);
  };

  const createCanvasForImageToSaveToServer = (
    imageUrl,
    locationData,
    placeName
  ) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.crossOrigin = "anonymous"; // Allow cross-origin image loading
      img.src = imageUrl;

      img.onload = () => {
        // Set canvas size to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the uploaded image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Create the blob from canvas
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob); // Successfully created blob
          } else {
            reject("Blob creation failed");
          }
        }, "image/png");
      };

      img.onerror = () => reject("Image loading failed");
    });
  };

  const uploadImage = async () => {
    try {
      setisLoading(true); // Show the loader
      const allImagePayload = additionWorkDetail
        .map((item, index) => {
          if (item?.imgFile) {
            return createCanvasForImageToSaveToServer(
              previewImages[index],
              locationData
            )
              .then((blob) => {
                const formData = new FormData();
                formData.append("uploadedFile", blob);

                // Upload the formData
                return axios.post(
                  `${base_url}/api/install/work/image/${item._id}`,
                  formData
                );
              })
              .catch((error) => {
                console.error("Error creating canvas or uploading:", error);
                return null; // Or handle the error accordingly
              });
          } else return false;
        })
        .filter(Boolean);
      // await new Promise((resolve) => setTimeout(resolve, 1 * 60 * 1000)); // Delay for 1 minutes
      const res = await Promise.all(allImagePayload);

      // After uploading images, send shop name and address to Telegram
      await axios.post(`${base_url}/api/install/sendTelegramMessage`, {
        shopName: shopName,
        shopAddress: shopAddress,
      });

      console.log(res);
      alertBox({
        data: { message: "Saved Successfully" },
      });
      navigate("/installCompanyEmployeeList");
    } catch (error) {
      console.log(error);
      alertBox({
        data: { message: "Something went wrong ..." },
      });
    }
  };
  const getImageUrl = (fileUrl) => {
    if (typeof fileUrl === "object") {
      return URL.createObjectURL(fileUrl?.get("uploadedFile"));
    } else return `${base_url}/images/${fileUrl}`;
  };

  // Fetch location (geolocation)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationData({ latitude, longitude });
        },
        (error) => console.error("Error fetching location:", error)
      );
    }
  }, []);

  // this function only for the getting address in text
  const fetchMapAddressDetails = () => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;

    return fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;

          let city = "";
          let state = "";
          let country = "";

          addressComponents.forEach((component) => {
            const types = component.types;

            // Get city/locality
            if (types.includes("locality")) {
              city = component.long_name;
            }

            // Get state/administrative area
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }

            // Get country
            if (types.includes("country")) {
              country = component.long_name;
            }
          });

          console.log(`City: ${city}, State: ${state}, Country: ${country}`);
          const placeName = data.results[0].formatted_address; // This gives the full address

          return {
            fullPlaceName: placeName,
            city,
            state,
            country,
          };
        }
      })
      .catch((error) => console.error("Error fetching place name:", error));
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    return {
      localTime: currentDate.toLocaleString("en-US", {
        hour12: true,
      }),
    };
  };
  const drawGmap = async (imgUrl, index) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    console.log(ctx);
    const placeName = await fetchMapAddressDetails();

    // Create a new Image object for the uploaded image
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imgUrl;

    img.onload = async () => {
      const imageAspectRatio = img.width / img.height;
      console.log({ imageAspectRatio });
      let paddingForBgRect;
      let paddingForGmapImg;

      let canvasWidth, canvasHeight;
      let rectBgHeight;
      let mapImgHeight;
      let mapImgWidth;
      let xPositionFirstText;
      let yPositionFirstText;
      let xPositionSecondText;
      let yPositionSecondText;

      let xPositionThirdText;
      let yPositionThirdText;

      let xPositionFourthText;
      let yPositionFourthText;

      let xPositionFifthText;
      let yPositionFifthText;

      let fontSizeFirst;
      let fontSizeRemaining;

      if (imageAspectRatio >= 16 / 9) {
        console.log("=>16 / 9");
        paddingForGmapImg = 15;
        paddingForBgRect = 15;

        canvasWidth = 800; // Set a fixed width
        canvasHeight = (canvasWidth * 9) / 16;
        rectBgHeight = 150;
        mapImgHeight = 150 - 2 * paddingForGmapImg;
        mapImgWidth = 150 - 2 * paddingForGmapImg;

        // first text
        xPositionFirstText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFirstText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 3.5;

        // second text
        xPositionSecondText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionSecondText =
          canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 5;

        // third text
        xPositionThirdText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionThirdText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 6.5;

        // fourth text
        xPositionFourthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFourthText =
          canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 8;

        // fifth text
        xPositionFifthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionFifthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 9.5;

        fontSizeFirst = canvasWidth * 0.02 + 4;
        fontSizeRemaining = canvasWidth * 0.02 - 5;
      } else if (imageAspectRatio >= 4 / 3) {
        console.log("=>4/3");
        paddingForGmapImg = 15;
        paddingForBgRect = 15;

        canvasWidth = 800;
        canvasHeight = (canvasWidth * 3) / 4;
        rectBgHeight = 200;
        mapImgHeight = 200 - 2 * paddingForGmapImg;
        mapImgWidth = 200 - 2 * paddingForGmapImg;
        xPositionFirstText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFirstText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 4.5;

        // second text
        xPositionSecondText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionSecondText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 6.5;

        // third text
        xPositionThirdText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionThirdText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 8.3;

        // fourth text
        xPositionFourthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFourthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 10;

        // fifth text
        xPositionFifthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionFifthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 11.5;

        fontSizeFirst = canvasWidth * 0.02 + 4;
        fontSizeRemaining = canvasWidth * 0.02 - 5;
      } else if (imageAspectRatio === 1) {
        console.log("=>1");
        paddingForGmapImg = 15;
        paddingForBgRect = 15;

        canvasWidth = 800;
        canvasHeight = canvasWidth; // Same width and height for square images
        rectBgHeight = 200;
        mapImgHeight = 200 - 2 * paddingForGmapImg;
        mapImgWidth = 200 - 2 * paddingForGmapImg;
        xPositionFirstText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFirstText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 4.5;

        // second text
        xPositionSecondText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionSecondText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 6.5;

        // third text
        xPositionThirdText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionThirdText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 8.3;

        // fourth text
        xPositionFourthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFourthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 10;

        // fifth text
        xPositionFifthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionFifthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 11.5;

        fontSizeFirst = canvasWidth * 0.02 + 4;
        fontSizeRemaining = canvasWidth * 0.02 - 5;
      } else {
        console.log("=>tall", imageAspectRatio);
        paddingForGmapImg = 3;
        paddingForBgRect = 3;

        canvasHeight = 800;
        canvasWidth = canvasHeight * imageAspectRatio;
        rectBgHeight = 122;
        mapImgHeight = 100 - 2 * paddingForGmapImg;
        mapImgWidth = 100 - 2 * paddingForGmapImg;
        xPositionFirstText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFirstText =
          canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 12.5;

        // second text
        xPositionSecondText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionSecondText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 16.5

        // third text
        xPositionThirdText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionThirdText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect *26.5

        // fourth text
        xPositionFourthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

        yPositionFourthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 31

        // fifth text
        xPositionFifthText =
          paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
        yPositionFifthText =
          canvasHeight -
          rectBgHeight -
          paddingForBgRect +
          paddingForBgRect * 36

        fontSizeFirst = canvasWidth * 0.02 + 3;
        fontSizeRemaining = canvasWidth * 0.02;
      }

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Draw the uploaded image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Draw Google Map Image using dummy location
      if (locationData) {
        const mapImg = new Image();
        mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=200x200&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;

        mapImg.crossOrigin = "anonymous";
        mapImg.onload = () => {
          console.log("k");

          // for background fill
          ctx.fillStyle = "rgba(0, 0, 0, 0.357)";

          const rectBgWidth = canvas.width - 2 * paddingForBgRect;

          ctx.fillRect(
            paddingForBgRect + mapImgWidth + 8,
            canvas.height - rectBgHeight - paddingForBgRect + 28,
            rectBgWidth - mapImgWidth,
            rectBgHeight - 2 * paddingForBgRect
          ); // x, y, width and height

          // for google map image
          ctx.drawImage(
            mapImg,
            paddingForGmapImg,
            canvas.height - mapImgHeight - paddingForGmapImg,
            mapImgWidth,
            mapImgHeight
          ); // img, xleft, yBottom, imagewidth, imageheight

          ctx.font = `bold ${fontSizeFirst}px Arial`;
          ctx.fillStyle = "white"; // Text color

          ctx.fillText(
            `${placeName.city}, ${placeName.state}, ${placeName.country}`,
            xPositionFirstText,
            yPositionFirstText
          );

          // tall images
          if (imageAspectRatio < 0.75) {
            const fullAddress = `${placeName.fullPlaceName}`;
            const maxWidth = rectBgWidth - mapImgWidth - 2 * paddingForBgRect;
            const addressLines = splitTextIntoLines(
              fullAddress,
              maxWidth,
              ctx,
              `${fontSizeRemaining + 2}px Arial`
            );
            console.log({ addressLines });

            // Draw address line by line inside the rectangle
            addressLines.forEach((line, index) => {
              ctx.fillText(
                line,
                paddingForBgRect + mapImgWidth + 8 + paddingForBgRect, // x position inside the rectangle
                yPositionSecondText + 3 + index * 13 // y position based on line index
              );
            });
          } else {
            ctx.font = `${fontSizeRemaining}px Arial`; // Set responsive font size
            ctx.fillText(
              `${placeName.fullPlaceName}`,
              xPositionSecondText,
              yPositionSecondText
            );
          }

          // for latitude
          ctx.fillText(
            `Lat ${locationData?.latitude}°`,
            xPositionThirdText,
            yPositionThirdText
          );

          // for longitude
          ctx.fillText(
            `Long ${locationData?.longitude}°`,
            xPositionFourthText,
            yPositionFourthText
          );

          // for time
          const { localTime } = getCurrentTime();

          ctx.fillText(`${localTime}`, xPositionFifthText, yPositionFifthText);

          const newPreviewImages = [...previewImages];
          newPreviewImages[index] = canvas.toDataURL("image/png"); // Store image based on index
          console.log({ index });

          if (newPreviewImages[index] !== previewImages[index]) {
            setPreviewImages(newPreviewImages);
          }
        };
      }
    };
  };

  // Helper function to split text into lines
  function splitTextIntoLines(text, maxWidth, ctx, font) {
    ctx.font = font;
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine); // Add the last line
    return lines;
  }
  const drawGoogleMapCanvasFunction = (fileUrl, index) => {
    if (typeof fileUrl === "object") {
      console.log("fileUrl in if", fileUrl);

      drawGmap(URL.createObjectURL(fileUrl?.get("uploadedFile")), index);
    } else {
      console.log("fileUrl in else", fileUrl);

      // return `${base_url}/images/${fileUrl}`;
    }
  };

  const deleteFn = async (additionalId, index) => {
    try {
      PermissionForDelete().then(async (res) => {
        if (res) {
          let response = await axios.delete(
            `${base_url}/api/install/work/image/${additionalId}`
          );
          alertBox({ data: { message: "Image Deleted Successfully" } });
          // allWorkDetailsFn();
          const newPreviewImages = [...previewImages];
          newPreviewImages[index] = "deleted";

          // Update the previewImages state
          setPreviewImages(newPreviewImages);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <div
            // onSubmit={handleSubmit}
            className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 "
          >
            <label className="font-bold" htmlFor="phone_no">
              {shopName}
            </label>
            {additionWorkDetail.map((item, index) => {
              return (
                <div
                  key={index}
                  className="border-4 border-l-cyan-500 flex  items-center  p-4"
                >
                  <div>
                    <p>Type: {item.requirment_type}</p>
                    <p>Width: {item.width} </p>
                    <p>Height: {item.height} </p>
                    <p>Quantity: {item.quantity} </p>

                    {item?.imgFile || item?.imageUrl ? (
                      <>
                        <canvas
                          ref={canvasRef}
                          style={{ display: "none" }}
                        ></canvas>
                        {previewImages[index] &&
                          previewImages[index] !== "deleted" && (
                            <img src={previewImages[index]} alt="Preview" />
                          )}
                        {console.log(
                          previewImages[index] === "deleted",
                          !previewImages[index],
                          previewImages[index] === "deleted" ||
                            !previewImages[index]
                        )}
                        {(previewImages[index] === "deleted" ||
                          previewImages[index] === "uploadingNew" ||
                          !previewImages[index]) && (
                          // eslint-disable-next-line jsx-a11y/alt-text
                          <img
                            className="hidden"
                            src={getImageUrl(item?.imgFile || item?.imageUrl)}
                            onLoad={() => {
                              console.log(
                                "previewImages[index]",
                                previewImages[index]
                              );

                              if (previewImages[index] === "deleted") {
                                // return null;
                              } else if (
                                previewImages[index] === "uploadingNew"
                              ) {
                                drawGoogleMapCanvasFunction(
                                  item?.imgFile || item?.imageUrl,
                                  index
                                );
                              } else {
                                drawGoogleMapCanvasFunction(
                                  item?.imgFile || item?.imageUrl,
                                  index
                                );
                              }
                            }}
                          />
                        )}

                        {previewImages[index] === "deleted" && (
                          <input
                            type="file"
                            id="shop_name_after_preview"
                            onChange={(event) => imageHandler(event, index)}
                            required
                          />
                        )}
                        {previewImages[index] &&
                          previewImages[index] !== "deleted" && (
                            <button
                              type="submit"
                              onClick={() => deleteFn(item._id, index)}
                              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                                isLoading ? "bg-slate-400" : ""
                              } `}
                              disabled={isLoading}
                            >
                              {/* {isLoading ? <Loader /> : "Delete"} */}
                              Delete
                            </button>
                          )}
                      </>
                    ) : (
                      <input
                        type="file"
                        id="shop_name"
                        onChange={(event) => imageHandler(event, index)}
                        // value={shop_name}
                        required
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => uploadImage()}
              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                isLoading ? "bg-slate-400" : ""
              } `}
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default InstallCreateForm;
