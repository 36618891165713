import React, { useContext, useEffect, useMemo, useState } from "react";
import { base_url, getAllForms } from "../../api";
import Table from "../../components/Table";
import { AuthContext } from "../../context";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as XLSX from "xlsx/xlsx.mjs";
// import Loader from "../../components/Loader";
import { Box, Button, Container, Grid, Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { AddModal } from "../../Modal/AddModal";
import { alertBox } from "../../utils/AlertDailog";
import { PermissionForDelete } from "../../utils/PermissionDialog";
import ExcelJS from "exceljs/dist/exceljs.min.js";

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

function RecentInstall() {
  const { token, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState("");

  const [company, setCompanyName] = useState("");
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [count, setCount] = useState(0);
  const handleOpen = () => setOpen(true);

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/install/work/recent`
      );
      setData(response.data.data.companyList);
    } catch (error) {}
  };

  const updateTable = (page) => {
    ViewCompanyFn();
  };

  const handleClose = () => {
    setImage("");
    setOpen(false);
  };
  useEffect(() => {
    updateTable(0);
  }, [token]);

  const exportExcelFile2 = async (id) => {
    let { data: successData } = await axios.get(
      `${base_url}/api/install/admin/work/image/${id}`
    );

    const data = successData.data.additioanlModel.map((item) => {
      item.shop_name = item.allWorkId.shop_name;
      item.address = item.allWorkId.address;
      delete item.__v;
      delete item._id;
      delete item.allWorkId;
      delete item.status;
      delete item.createdAt;
      delete item.updatedAt;
      return item;
    });
    console.log({ data });
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    sheet.columns = [
      {
        // header: "Shop Name",
        key: "shop_name",
        width: 10,
      },
      {
        // header: "Address",
        key: "address",
        width: 32 },
      {
        // header: "Type",
        key: "requirment_type",
        width: 20,
      },
      {
        // header: "Width",
        key: "width",
        width: 20,
      },
      {
        // header: "Height",
        key: "height",
        width: 15,
      },
      {
        // header: "Quantity",
        key: "quantity",
        width: 10,
      },
    ];
    const idx = { index: 0 };
    const promise = Promise.all(
      data?.map(async (product, index) => {
        let rowNumber = index * 2 + 0; // Adjusted row number to leave space for empty rows
        sheet.addRow({
          shop_name: product?.shop_name,
          address: product?.address,
          requirment_type: product?.requirment_type,
          width: product?.width,
          height: product?.height,
          quantity: product?.quantity,
        });
        sheet.addRow({}).height = 335;

        console.log(product?.thumbnail);

        const getImageDimensions = (imageUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              resolve({ width, height });
            };
            img.onerror = (error) => {
              reject(error);
            };
            img.src = imageUrl;
          });
        };
        

        const image = product?.imageUrl;
        const dimensions = await getImageDimensions(image);
        const imageWidth = dimensions.width;
        const imageHeight = dimensions.height;
   
        const result = await toDataURL(image);
        const splitted = image.split(".");
        const extName = splitted[splitted.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });

        sheet.mergeCells(`A${rowNumber}:F${rowNumber}`);
        idx.index = rowNumber;
        sheet.addImage(imageId2, {
          tl: { col: 1, row: rowNumber + 1 },
          ext: { width: imageWidth, height: imageHeight },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(6);

      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          // Add border to each cell in the row
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

           // Center the image in the cell
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };

        });
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  
 
  const deleteEntity = async (allWorkId) => {
    try {    
      let currStoreForDelete = data.find((_item) => allWorkId === _item._id);
      if (currStoreForDelete) {
        PermissionForDelete().then(async (res) => {
          if (res) {
            await axios.delete(`${base_url}/api/install/work/${allWorkId}`);
            alertBox({ data: { message: "Store Deleted Successfully" } });
            updateTable();
            return;
          }
        });
      } 
    } catch (error) {
      console.log(error);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "updatedAt",
        callback: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Time",
        accessor: "updatedAt",
        callback: (value) => {
          return moment(value).format("hh:mm A");
        },
      },
      {
        Header: "Shop Name",
        accessor: "shop_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Assiagned to",
        accessor: "assiagnedId.name",
      },
      {
        Header: "Export",
        accessor: "_id",
        callback: (id) => {
          return (
            <button type="submit" onClick={() => exportExcelFile2(id)}>
              <ImportExportIcon />
            </button>
          );
        },
      },
      {
        Header: "Delete",
        accessor: "_id",
        callback: (id) => {
          return (
            <button
              type="submit"
              onClick={() => {
                deleteEntity(id);
              }}
            >
              <DeleteIcon />
            </button>
          );
        },
      },
    ],
    [token]
  );

  // if (isLoading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );

  const createCompany = async () => {
    try {
      let payload = {
        name: company,
        logoUrl: imageUrl,
        total: 0,
        completed: 0,
        pending: 0,
        cancelled: 0,
        revisit: 0,
      };
      const res = await axios.post(`${base_url}/company`, payload);

      setData((prev) => [...prev, payload]);
      alert("AlertComapef");

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              justifyContent: { xs: "center", sm: "flex-end" },
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {/* <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button> */}
          </Box>
          <Table
            columns={columns}
            datas={data}
            updateTable={updateTable}
            count={count}
          />
          <AddModal open={open} setOpen={setOpen} />
        </Container>
      </Grid>
    </Grid>
  );
}

export default RecentInstall;
