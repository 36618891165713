import React, { useContext, useEffect, useMemo, useState } from "react";
import { getAllForms, base_url } from "../../api";
import Table from "../../components/Table";
import { AuthContext } from "../../context";
// import Loader from "../../components/Loader";
import { Box, Button, Container, Grid } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";

function InstallEmployeeView() {
  const { token, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [employeeName, setEmployeeName] = useState(0);
  const [statusArray, setStatusArray] = useState(0);
  const [empUserId, setEmpId] = useState(null);
  const { userId } = useParams();

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(
        // api is fix
        `${base_url}/api/install/employee/view/${userId}`
      );
      setData(response.data.data.emplist);

      setStatusArray(response.data.data.statusArray);
      setEmployeeName(response.data.data.employeeName);
      setEmpId(response.data.data.empId);
    } catch (error) {}
  };
  const gernateNextLink = (id) => {
    // change api 
    return `/installEmployeeUserCompany/user/company/${id}/${empUserId}`;
  };

  const updateTable = (page) => {
    ViewCompanyFn();
  };

  useEffect(() => {
    // if (token)
    updateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const calculte = () => {};
  // console.log(statusArray)

  const columns = [
    {
      Header: "Company Name",
      accessor: "_id.name",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
      Header: "Compelete",
      accessor: "status.completed",
    },
    {
      Header: "Pending",
      accessor: "status.pending",
    },
    {
      Header: "Cancelled",
      accessor: "status.cancelled",
    },
    {
      Header: "Revisit",
      accessor: "status.revisit",
    },
    {
      Header: "View Company",
      accessor: "_id.companyId",
      callback: (id) => {
        return <Link to={gernateNextLink(id)}>View Company</Link>;
      },
    },
    // {
    //   Header: "Delete Employee",
    //   // accessor: "user.createdBy",
    //   accessor: 0,
    // },
    // {
    //   Header: "Created At",
    //   accessor: "createdAt",
    //   callback: (value) => {
    //     return moment(value).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   Header: "Download",
    //   accessor: "_id",
    //   callback: (id) => {
    //     return (
    //       <form method="post" action={`/api/user/download-ppt/${id}`}>
    //         <input type="hidden" value={token} name="authorization" />
    //         <button type="submit">
    //           <CloudDownloadIcon />
    //         </button>
    //       </form>
    //     );
    //   },
    //   // onClick: (id) => {
    //   //   getFormPpt({ id, token });
    //   // },
    // },
  ];

  // if (isLoading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <h1
          style={{
            textAlign: "center",
            color: "#283747",
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          {employeeName}
        </h1>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              justifyContent: { xs: "center", sm: "flex-end" },
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {user?.role === "admin" && (
              <Button
              className="btnCommon"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate("/createUser ");
                }}
                sx={{
                  alignItems: "left",
                  backgroundColor: "#15803d",
                  color: "white",

                  ":hover": {
                    backgroundColor: "#15803d",
                    color: "white",
                  },
                }}
              >
                Add Employee
              </Button>
            )}
            {/* <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button> */}
          </Box>
          {empUserId && (
            <Table
              columns={columns}
              datas={data}
              updateTable={updateTable}
              count={count}
            />
          )}
        </Container>
      </Grid>
    </Grid>
  );
}

export default InstallEmployeeView;
